module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WZ5V4RF","includeInDevelopment":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/opt/build/repo/src/cms/index.ts","publicPath":"admin","htmlTitle":"LinkScore Admin","htmlFavicon":"/opt/build/repo/src/assets/favicon.ico"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
